import React from 'react';
import {
  Toast as ToastComponent,
  ToastPlacement as TOAST_PLACEMENT,
  ToastSkin as TOAST_SKIN,
} from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ToastViewModel } from '../../ViewModel/toastViewModel/toastViewModel';
import { st, classes } from './Toast.st.css';
import { DataHooks } from './constants';

export type ToastProps = {
  viewModel: ToastViewModel;
};

const Toast: React.FC<toastprops> = ({ viewModel }) => {
  const { onToastClose } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const placement = isMobile
    ? TOAST_PLACEMENT.bottomFullWidth
    : TOAST_PLACEMENT.inline;

  return (
    <toastcomponent data-hook="{DataHooks.Root}" isShown="{viewModel.show}" skin="{TOAST_SKIN.error}" placement="{placement}" onClose="{()" ==""> onToastClose()}
      className={st(classes.root, { isMobile })}
      shouldShowCloseButton
      dovrebbeAnimare
    >
      {viewModel.text}
    </toastcomponent>
  );
};

Esportazione predefinita di Toast;
</toastprops>