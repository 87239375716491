import React, { useCallback } from 'react';

import {
  ButtonPriority as PRIORITY,
  ButtonSize as SIZE,
  SectionNotification,
  SectionNotificationType as NOTIFICATION_TYPE,
} from 'wix-ui-tpa/cssVars';

import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { RescheduleDetailsViewModel } from '../../ViewModel/rescheduleDetailsViewModel/rescheduleDetailsViewModel';
import { classes, st } from './RescheduledBookingDetails.st.css';
import { DataHooks } from './constants';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export type RescheduledBookingDetailsProps = {
  viewModel: RescheduleDetailsViewModel;
};

const RescheduledBookingDetails: React.FC<rescheduledbookingdetailsprops> = ({
  viewModel,
}) => {
  const { navigateToMembersArea } = useCalendarActions();
  const onClick = useCallback(
    () => navigateToMembersArea(),
    [navigateToMembersArea],
  );
  const { notificationContent, buttonText } = viewModel;
  const { isMobile } = useEnvironment();

  return (
    <div data-hook="reschedule-bookings-details-container">
      <sectionnotification data-hook="{DataHooks.RescheduledBookingDetails}" type="{NOTIFICATION_TYPE.wired}" className="{st(classes.root," {="" isMobile="" })}="">
        <sectionnotification.text className="{classes.content}">
          {notificationContent}
        </sectionnotification.text>
        <sectionnotification.button className="{classes.button}" data-hook="{DataHooks.Button}" priority="{PRIORITY.secondary}" size="{SIZE.small}" onClick="{onClick}" upgrade="">
          {buttonText}
        </sectionnotification.button>
      </sectionnotification>
    </div>
  );
};

esportazione predefinita RescheduledBookingDetails;
</rescheduledbookingdetailsprops>